import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#FFFFFF',
      neutralLight1: '#FAFAFA',
      neutralLight2: '#F3EFEA',
      neutralLight3: '#F0CC94',
      neutralLight4: '#CDB083',
      primaryDark: '#293546',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'Italiana', serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
